<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Multiselect from "@vueform/multiselect";

import { treatmentData } from "./data-treatment";

export default {
    setup() {
    return { v$: useVuelidate() };
  },
    page: {
        title: "Centre de Traitement",
    },
    components: {
        Layout,
        PageHeader,
        Multiselect
    },
    data() {
        return {
            treatmentData: treatmentData,
            title: "Centre de traitement",
            items: [
                {
                    text: "Paramétrage",
                    href: "/settings",
                },
                {
                    text: "Centres de traitement",
                    active: true,
                },
            ],
            showModal: false,
      submitted: false,
      users: {
        name: "",
        adresse: "",
      },
      value1: null,
      options: [
        "En activité",
        "Désactivé",
        "Maintenance"
      ],
        };
    },
    validations: {
    users: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      adresse: {
        required: helpers.withMessage("Adresse is required", required),
      },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.showModal = false;
        this.users = {};
        this.value1 = [];
      }
      this.submitted = false;
    },
  },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12 d-none d-lg-block">
                <button class="btn btn-primary float-end" @click="showModal = true">
                    <i class="bx bx-plus-circle align-middle me-1"></i> Ajouter un centre de Traitement
                </button>
            </div>
        </div>
    <b-modal v-model="showModal" title="Ajouter un centre de traitement" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Nom du centre</label>
              <input
                id="name"
                v-model="users.name"
                type="text"
                class="form-control"
                placeholder="Insert name"
                :class="{
                  'is-invalid': submitted && v$.users.name.$error,
                }"
              />
               <div
                v-if="submitted && v$.users.name.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.users.name.required.$message">{{
                  v$.users.name.required.$message
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Adresse</label>
              <input
                id="adresse"
                v-model="users.adresse"
                type="text"
                class="form-control"
                placeholder="Insert adresse"
                :class="{
                  'is-invalid': submitted && v$.users.adresse.$error,
                }"
              />
               <div
                v-if="submitted && v$.users.adresse.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.users.adresse.required.$message">{{
                  v$.users.adresse.required.$message
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="projects">Statut</label>
            <multiselect
              v-model="value1"
              :options="options"
            ></multiselect>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">Fermer</b-button>
          <b-button type="submit" variant="success" class="ms-1"
            >Créer le centre</b-button
          >
        </div>
      </form>
    </b-modal>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="table-responsive">
                        <table
                            class="table project-list-table table-nowrap table-centered table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 100px">#</th>
                                    <th scope="col">Centres de Traitement</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Statut</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="treatment in treatmentData" :key="treatment.id">
                                    <td>
                                        <img :src="`${treatment.image}`" alt class="avatar-sm" />
                                    </td>
                                    <td>
                                        <h5 class="text-truncate font-size-14">
                                            <a href="javascript: void(0);" class="text-dark">{{
                                                    treatment.text
                                            }}</a>
                                        </h5>
                                        <p class="text-muted mb-0">{{ treatment.subtext }}</p>
                                    </td>
                                    <td>{{ treatment.date }}</td>
                                    <td>
                                        <span class="badge" :class="{
                                            'bg-success': `${treatment.status}` === 'En activité',
                                            'bg-warning': `${treatment.status}` === 'Maintenance',
                                            'bg-danger': `${treatment.status}` === 'Désactivé',
                                        }">{{ treatment.status }}</span>
                                    </td>
                                    <td>
                                        <router-link to="" class="action-icon text-success me-2">
                                            <i class="mdi mdi-eye font-size-18"></i>
                                        </router-link>
                                        <router-link to="" class="action-icon text-primary me-2">
                                            <i class="mdi mdi-pencil font-size-18"></i>
                                        </router-link>
                                        <router-link to="" class="action-icon text-danger">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

    </Layout>
</template>
